<template>
  <div class="home pb0">
    <CommonHeader :title="(account_type == 2 ? '委托方提现' : '退款')" />
    <div class="withdrawal-title-view">
      <div class="withdrawal-title">{{ account_type == 2 ? '提现' : '退款' }}金额<template v-if="merchantInfo">({{ merchantInfo.fee_type_text }})</template></div>
      <div class="input-view flex align-items-c">
        <div class="mr10"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template></div>
        <input
          v-model.number="extract_price"
          type="number"
          :placeholder="`请输入${ account_type == 2 ? '提现' : '退款' }金额`"
        />
      </div>
    </div>
    <div class="can-withdrawal flex align-items-c ft14">
      <div class="flex-one color-gray">可{{ account_type == 2 ? '提现' : '退款' }} {{ formatPriceNumber(balance, 2) }}</div>
      <div
        class="all"
        @click="changeAllAction"
      >全部{{ account_type == 2 ? '提现' : '退款' }}</div>
    </div>
    <div
      class="btn"
      :style="rightBtnStyle"
      @click="saveExtractAction"
    >确认{{ account_type == 2 ? '提现' : '退款' }}</div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, ref, onBeforeMount, watch } from "vue";
import { applyExtract, accountInfo } from "@/api/user";
import { Dialog, Toast } from "vant";
import { formatPriceNumber } from "@/utils/util";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const extract_price = ref(""); // 提现金额
    const account_type = ref("");
    const balance = ref("");

    onBeforeMount(() => {
      account_type.value = route.query.type;
      accountInfo({ account_type: account_type.value })
        .then((res) => {
          balance.value = res.data.now_money;
        })
        .catch((message) => Toast(message));
    });

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const changeAllAction = () => {
      extract_price.value = balance.value;
    };

    watch(extract_price, (val) => {
      if (Number(val) > balance.value) {
        extract_price.value = Number(balance.value);
      }
    });

    let lock = false;
    const saveExtractAction = () => {
      if (!extract_price.value || isNaN(extract_price.value)) {
        Toast(`请输入正确的${account_type.value == 2 ? "提现" : "退款"}金额`);
        return false;
      }
      Dialog.confirm({
        title: `确定申请${account_type.value == 2 ? "提现" : "退款"}${
          extract_price.value
        }${merchantInfo.value.fee_type_text}？`,
      }).then(() => {
        if (lock) return false;
        lock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        applyExtract({
          extract_price: extract_price.value,
          extract_type: account_type.value,
        })
          .then(() => {
            Toast.clear();
            Toast(`${account_type.value == 2 ? "提现" : "退款"}申请成功`);
            lock = false;
            setTimeout(() => {
              router.back();
            }, 1400);
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            lock = false;
          });
      });
    };

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      rightBtnStyle,
      colorData,
      extract_price,
      balance,
      account_type,
      merchantInfo,

      saveExtractAction,
      changeAllAction,
      formatPriceNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f5f5f5;
}
.withdrawal-title-view {
  padding-top: 64px;
}
.withdrawal-title {
  margin: 0 32px 32px 32px;
  font-size: 28px;
  color: #000;
  font-weight: 500;
}

.input-view {
  height: 96px;
  border: 2px solid #eee;
  background: #fff;
  border-radius: 8px;
  margin: 0 32px;
  padding: 22px 32px;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 500;
  color: #000;

  input {
    border: none;
  }
}

.can-withdrawal {
  margin: 32px;

  .all {
    color: #347ff4;
  }
}

.btn {
  height: 72px;
  line-height: 72px;
  text-align: center;
  margin: 32px;
}
</style>